<template>
  <div class="gateway">
    <div>
      <a-button type="primary" @click="addGateway">添加</a-button>
    </div>

    <div class="des_box">
      <a-tag color="blue">
        <div>
          <a-icon type="info-circle" theme="filled" style="color: #2d8cf0" />
          谨慎添加或修改路由,如果修改不当,将影响正常访问！<a
            style="color: #2d8cf0"
            @click="refreshGateway"
            >手动刷新网关</a
          >
        </div>
      </a-tag>
    </div>

    <a-table
      :columns="columns"
      :data-source="gatewayData"
      bordered
      :pagination="pagination"
      @change="tableChange"
      :scroll="{ x: 1300 }"
      :rowKey="(record) => record.appId"
      style="margin-top: 15px"
    >
      <div slot="method" slot-scope="text, record">
        <div v-if="!record.url">
          <a-tag color="green"> 负载均衡 </a-tag>
          {{ record.serviceId }}
        </div>
        <div v-else>
          <a-tag color="blue">反向代理</a-tag>
          {{ record.url }}
        </div>
      </div>
      <div slot="stripPrefix" slot-scope="text">
        {{ text ? '是' : '否' }}
      </div>
      <div slot="retryable" slot-scope="text">
        {{ text ? '是' : '否' }}
      </div>
      <div slot="status" slot-scope="text">
        {{ text == 1 ? '启用' : '禁用' }}
      </div>
      <div slot="operation" slot-scope="text, record">
        <a @click="editGateway(record)" style="margin-right: 10px">编辑</a>
        <a @click="deleteGateway(record)" style="margin-right: 10px">删除</a>
      </div>
    </a-table>
    <!-- 添加、编辑网关路由 -->
    <add-gateway-routing
      :visible.sync="gatewayVisible"
      v-if="gatewayVisible"
      :record="record"
      @refresh="refresh"
    ></add-gateway-routing>
  </div>
</template>

<script>
import addGatewayRouting from './modal/addGatewayRouting.vue'

const columns = [
  {
    dataIndex: 'routeDesc',
    key: 'routeDesc',
    title: '路由名称',
    width: '200px',
    align: 'left',
  },
  {
    dataIndex: 'routeName',
    key: 'routeName',
    title: '路由标识',
    width: '150px',
    align: 'left',
  },
  {
    dataIndex: 'path',
    key: 'path',
    title: '路由前缀',
    width: '250px',
    align: 'left',
  },
  {
    dataIndex: 'method',
    key: 'method',
    title: '路由方式',
    scopedSlots: { customRender: 'method' },
    width: '300px',
    align: 'left',
  },
  {
    dataIndex: 'stripPrefix',
    key: 'stripPrefix',
    title: '忽略前缀',
    scopedSlots: { customRender: 'stripPrefix' },
    width: '100px',
    align: 'left',
  },
  {
    dataIndex: 'retryable',
    key: 'retryable',
    title: '失败重试',
    scopedSlots: { customRender: 'retryable' },
    width: '100px',
    align: 'left',
  },
  {
    dataIndex: 'status',
    key: 'status',
    title: '状态',
    scopedSlots: { customRender: 'status' },
    width: '100px',
    align: 'left',
  },

  {
    dataIndex: 'operation',
    key: 'operation',
    title: '操作',
    scopedSlots: { customRender: 'operation' },
    fixed: 'right',
    width: '130px',
    align: 'left',
  },
]

export default {
  name: 'GatewayRouting',
  components: { addGatewayRouting },
  data() {
    return {
      columns,
      gatewayData: [],
      record: {},
      gatewayVisible: false,
      pagination: {
        current: 1, // 默认当前页数
        pageSize: 10, // 默认当前页显示数据的大小
        total: 20,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        pageSizeOptions: ['10', '15', '20'],
      },
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getGatewayRoutingList({
        page: this.pagination.current,
        limit: this.pagination.pageSize,
      })
    },
    refresh() {
      this.gatewayVisible = false
      this.init()
    },
    /* 获取网关路由列表 */
    getGatewayRoutingList(params) {
      let _params = {
        ...params,
        // APP_ID: 1552274783265,
        // SIGN_TYPE: 'SHA256',
      }

      return new Promise((resolve, reject) => {
        this.$api.getGatewayList(_params).then((res) => {
          if (res.code === 0) {
            this.gatewayData = res.data.records
            this.pagination.total = Number(res.data.total)
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 手动刷新网关 */
    refreshGateway() {},
    /* 添加网关 */
    addGateway() {
      this.record = {}
      this.gatewayVisible = true
    },
    /* 分页 */
    tableChange(pagination) {
      let { current, pageSize } = pagination
      this.pagination.current = current
      this.pagination.pageSize = pageSize
      this.getGatewayRoutingList({ page: current, limit: pageSize })
    },
    /* 编辑路由网关 */
    editGateway(record) {
      this.record = record
      this.gatewayVisible = true
    },
    /* 删除网关路由 */
    deleteGateway(record) {
      console.log(record)
      let _this = this
      this.$confirm({
        title: '确定删除吗？',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          console.log('OK')
          _this.$api.deleteGatewayRoute(record.routeId).then((res) => {
            if (res.code === 0) {
              _this.$message.success('删除成功')
              _this.refresh()
            } else {
              _this.$message.error('删除失败')
            }
          })
        },
        onCancel() {
          console.log('Cancel')
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.gateway {
  background-color: #fff;
  padding: 15px;
}

/deep/ .ant-table-body {
  background-color: #fff;
  font-size: 12px;
}

/deep/ .ant-table-row-cell-break-word {
  font-size: 12px;
}

/deep/ .ant-table-column-title {
  font-weight: bold;
}

/deep/ .ant-table-align-center,
.ant-table-align-left {
  height: 42px !important;
  padding: 0;
  font-family: 'PingFang SC Bold';
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #333;
}

/deep/ .ant-table-tbody > tr > td {
  padding: 10px 16px 10px 16px;
}

/deep/ .des_box {
  margin-top: 10px;
  .ant-tag {
    width: 100%;
    height: 30px;
    div {
      height: 100%;
      line-height: 30px;
      color: #515a6e;
      span {
        color: #c7254e;
        background-color: #f6f6f6;
      }
    }
  }
}
</style>
