<!--
 * @Author: XSJ 13592841305@163.com
 * @Date: 2023-08-22 10:33:12
 * @LastEditors: XSJ 13592841305@163.com
 * @LastEditTime: 2023-08-22 14:49:38
 * @FilePath: \cz-operation\src\views\systemManage\modal\addGatewayRouting.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-modal
    :visible="visible"
    :title="isEdit ? '编辑路由' : '添加路由'"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="路由名称">
        <a-input
          placeholder="请输入内容"
          v-decorator="[
            'routeDesc',
            {
              initialValue: record.routeDesc || '',
              rules: [{ required: true, message: '请输入路由名称' }],
            },
          ]"
        >
        </a-input>
      </a-form-item>
      <a-form-item label="路由标识">
        <a-input
          placeholder="默认使用服务名称{application.name}"
          v-decorator="[
            'routeName',
            {
              initialValue: record.routeName || '',
              rules: [{ required: true, message: '请输入路由标识' }],
            },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item label="路由前缀">
        <a-input
          placeholder="/{path}/**"
          v-decorator="[
            'path',
            {
              initialValue: record.path || '',
              rules: [{ required: true, message: '请输入路由前缀' }],
            },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item label="路由方式">
        <a-select
          @change="routeMethod"
          v-decorator="['method', { initialValue: record.url ? 2 : 1 || 1 }]"
        >
          <a-select-option :value="1">负载均衡（serviceId）</a-select-option>
          <a-select-option :value="2">反向代理（url）</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="负载均衡" v-if="method == 1">
        <a-input
          placeholder="服务名称application.name"
          v-decorator="[
            'serviceId',
            {
              initialValue: record.serviceId || '',
              rules: [{ required: true, message: '服务名称不能为空' }],
            },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item label="反向代理" v-if="method == 2">
        <a-input
          placeholder="http://localhost:8080"
          v-decorator="[
            'url',
            {
              initialValue: record.url || '',
              rules: [{ required: true, message: '服务地址不能为空' }],
            },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item label="忽略前缀">
        <a-radio-group
          v-decorator="[
            'stripPrefix',
            { initialValue: record.stripPrefix || 0 },
          ]"
        >
          <a-radio-button :value="0">否</a-radio-button>
          <a-radio-button :value="1">是</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="失败重试">
        <a-radio-group
          v-decorator="['retryable', { initialValue: record.retryable || 0 }]"
        >
          <a-radio-button :value="0">否</a-radio-button>
          <a-radio-button :value="1">是</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="状态">
        <a-radio-group
          v-decorator="['status', { initialValue: record.status || 1 }]"
        >
          <a-radio-button :value="0">禁用</a-radio-button>
          <a-radio-button :value="1">启用</a-radio-button>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'AddGatewayRouting',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isEdit: Object.keys(this.record).length > 0,
      form: this.$form.createForm(this),
      method: 1,
    }
  },
  created() {
    if (this.isEdit) {
      this.method = this.record.url ? 2 : 1
    }
  },
  methods: {
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values)
          let { method, ...data } = values

          if (this.isEdit) {
            /* 修改 */
            // data = {

            // }
            if (method == 1) {
              data = {
                ...data,
                routeId: this.record.routeId,
                url: '',
              }
            } else if (method == 2) {
              data = {
                ...data,
                routeId: this.record.routeId,
                serviceId: '',
              }
            }

            this.updateGateway(data)
              .then(() => {
                this.$message.success('修改成功')
                this.$emit('refresh')
              })
              .catch(() => {
                this.$message.error('修改出错')
              })
          } else {
            /* 添加 */

            this.addGateway(data)
              .then(() => {
                this.$message.success('添加成功')
                this.$emit('refresh')
              })
              .catch(() => {
                this.$message.error('添加出错')
              })
          }
        }
      })
    },
    handleCancel() {
      this.$emit('update:visible', false)
    },
    /* 路由方式切换 */
    routeMethod(value) {
      console.log(value)
      this.method = value
    },
    /* 添加网关路由 */
    addGateway(data) {
      return new Promise((resolve, reject) => {
        this.$api.addGatewayRoute(data).then((res) => {
          if (res.code === 0) {
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 修改网关路由 */
    updateGateway(data) {
      return new Promise((resolve, reject) => {
        this.$api.updateGateway(data).then((res) => {
          if (res.code === 0) {
            resolve()
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="less" scoped></style>
